/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
/* App imports */
import Layout from '../components/layout'
import Seo from '../components/seo'
import Utils from '../utils'
import {
  Container,
  Photo,
  Content,
  IconsContainer,
  IconsWrapper,
} from '../style/pages/index.js'
import Mailto from 'react-protected-mailto'

const About = ({
  data: { profilePhoto, styrenePhoto, skillIcons, toolIcons },
}) => {
  return (
    <Layout>
      <Seo
        title="Cookie Policy"
        description="Our Cookie Policy"
        path="cookie-policy"
      />
      <Container>
        <Photo>
          <Img fluid={profilePhoto.childImageSharp.fluid} />
        </Photo>
        <Content>
          <h1>Hi, I'm Stephen</h1>
          <div className="aboutRow">
            <p>
              It's nice to meet you! I'm a full stack web developer who got my
              start 10 years ago during my PhD when I was studying how molecules
              move around on surfaces. I ran into trouble keeping it all
              straight when the number of images I was collecting reached into
              the hundreds, so I built a little website to keep track of them
              all. The thrill of creating full stack web applications was so
              strong that once I got a taste I couldn't stop making them.
            </p>
            <Photo>
              <Img fluid={styrenePhoto.childImageSharp.fluid} />
            </Photo>
          </div>
          <p>
            It's been exciting to see the web evolve over the past decade. We
            now live in a golden age for web developers. The rapid growth of
            javascript means that today we only really need to learn one
            programming language to create an entire web app and even more
            excitingly, with the development of Dockers and Serverless tools, we
            as full stack developers can deploy our own applications in fault
            tolerant configurations that would have taken a whole team DevOps
            just a few years ago.
          </p>
          <p>
            I'd love to hear from you! Hit me up on{' '}
            <a href="https://twitter.com/codemochi">twitter (@codemochi)</a> or
            email (
            <Mailto
              email="stephen@codemochi.com"
              headers={{ subject: 'Question from the blog' }}
            />
            )
          </p>
          <br />
          <h2>Skills</h2>
          <ImageList edges={skillIcons.edges} />
          <h2>Tools</h2>
          <ImageList edges={toolIcons.edges} />
        </Content>
      </Container>
    </Layout>
  )
}

About.propTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    styrenePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    skillIcons: PropTypes.object.isRequired,
    toolIcons: PropTypes.object.isRequired,
  }),
}

const ImageList = ({ edges }) => (
  <IconsContainer>
    {edges
      .sort((edgeA, edgeB) =>
        edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase() ? 1 : -1
      )
      .map(({ node: { name, childImageSharp } }) => (
        <IconsWrapper key={name}>
          <Img
            fixed={childImageSharp.fixed}
            alt={name + ' logo'}
            title={name}
          />
          <label>
            {iconsNameMap[name] ? iconsNameMap[name] : Utils.capitalize(name)}
          </label>
        </IconsWrapper>
      ))}
  </IconsContainer>
)

ImageList.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export const query = graphql`
  {
    profilePhoto: file(name: { eq: "profile-photo" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    styrenePhoto: file(name: { eq: "styrene" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    skillIcons: allFile(filter: { dir: { regex: "/about/skills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    toolIcons: allFile(filter: { dir: { regex: "/about/tools$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`
// Use to set specific icons names
const iconsNameMap = {
  css: 'CSS',
  html: 'HTML',
  jquery: 'JQuery',
  nodejs: 'Node.js',
  vuejs: 'Vue.js',
  gruntjs: 'Grunt.js',
}

export default About
