import styled from 'styled-components'
import { flexCenter, label, frameMedium } from '../mixins'

export const Container = styled.div`
  ${({ theme }) => `
     ${flexCenter()}
     ${frameMedium({ theme })}
    @media ${theme['mobile']} {
        flex-direction: column;
        padding: ${theme['padding-small']} ${theme['padding-zero']};
    }  
       `};
`

export const Photo = styled.div`
  ${({ theme }) => `
  width: 30%;
  box-sizing: border-box;
  margin-right: auto;
  margin-bottom: auto;
  border: 1px solid ${theme['main-color']};
  @media ${theme['mobile']} {
    width: 100%;
    margin: auto;
  }  
       `};
`

export const Content = styled.div`
  ${({ theme }) => `
  width: 70%;
  box-sizing: border-box;
  h1,
  h2,
  p {
    text-align: center;
  }
  h1 {
    border-bottom: 1px solid ${theme['main-color']};
    padding-bottom: ${theme['padding-small']};
  }
  h2 {
    font-family: 'CustomBoldFont', sans-serif;
  }
  h2,
  p {
    padding-left: ${theme['padding-medium']};
  }
  .aboutRow{
    padding: ${theme['padding-medium']} ${theme['padding-zero']}
    display: flex;
    p{
      width: 80%
      padding: ${theme['padding-medium']}
    }
    img{
      width: 20%;
      height: 20%;
    }
  }
  @media ${theme['mobile']} {
    width: 100%;
    h1,
    h2,
    p {
      padding: ${theme['padding-small']} ${theme['padding-zero']};
      text-align: center;
    }
  }
       `};
`

export const IconsContainer = styled.div`
  ${({ theme }) => `
     ${flexCenter()}
    `}
`

export const IconsWrapper = styled.div`
  ${({ theme }) => `
    ${frameMedium({ theme })}
    ${flexCenter()}
    ${label({ theme }, theme['main-color'], theme['white'])}
    label {
        color: ${theme['header-theme']}; 
        padding-left: ${theme['padding-small']};
        }
        @media ${theme['mobile']} {
        flex-basis: 30%;
        }
  `}
`
